// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// // bootstrap
// @import "~bootstrap/scss/bootstrap";

// If you want to add something do it here
@import "custom";
