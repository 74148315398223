// Here you can add other styles
.table {
  &.trx-table {
    tr.flagged-tr {
      background: #f0eeee;
      color: #999999;
      // opacity: 0.8;
      &.bg-light-red{
        background: #ffc8c8;
      }
    }
  }
}

.dtable-wrapper {
  width: auto;
}
.c-datatable-filter {
  input {
    width: 90% !important;
  }
  @media screen and (min-width: 576px) {
    &.col-sm-6 {
      flex: 0 0 80% !important;
      max-width: 80% !important;
    }
  }
}
.c-datatable-filter + div {
  @media screen and (min-width: 576px) {
    &.col-sm-6 {
          flex: 0 0 20% !important;
    max-width: 20% !important;
    }
  }
}

.c-sidebar {
  color: #fff;
  background: #1a4975;
}

@media (hover: hover), not all {
  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #1a4975 !important;
    background: #cddc39;
  }
}

.btn-primary {
  color: #1a4975;
  background-color: #cddc39;
  border-color: #0f3e6b;
}

@media (hover: hover), not all {
  .btn-primary:hover {
    color: #ffffff;
    background-color: #1a4975;
    border-color: #0f3e6b;
  }
}

.btn-outline-primary {
  color: #1a4975;
  border-color: #1a4975;
}

@media (hover: hover), not all {
  .btn-outline-primary:hover {
    color: #1a4975;
    background-color: #cddc39;
    border-color: #1a4975;
  }
}

.page-item.active .page-link {
  background-color: #1a4975;
  border-color: #0f3e6b;
}

a {
  color: #1a4975;
}

.page-link {
  color: #1a4975;
}

.cursor-pointer {
  cursor: pointer;
}